import Vue from 'vue';
import VueRouter from 'vue-router';
import Appver from "@/views/Appver/Appver";
import Home from "../views/Home/Home";
import Login from "@/views/Login/Login"
import store from '@/store'

Vue.use(VueRouter);

const routes = [
	{
		path: '/appver',
		name: 'Appver',
		component: Appver
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('@/views/Login/Register'),
	},
	{
		path: '/forgot',
		name: 'Forgot',
		component: () => import('@/views/Login/Forgot'),
	},
	{
		path: '/',
		name: 'Home',
		component: Home,
		redirect: '/my-account',
		children: [
			{
				path: '/my-account',
				name: 'AccountIndex',
				component: () => import('@/views/Account/index'),
				meta: {
					requireAuth: true
				}
			},
			{
				path: '/product-registration',
				name: 'ProductRegistrationIndex',
				component: () => import('@/views/ProductRegistration/index'),
				meta: {
					requireAuth: true
				}
			},
			{
				path: '/software-activation',
				name: 'SoftwareActivationIndex',
				component: () => import('@/views/SoftwareActivation/index'),
				meta: {
					requireAuth: true
				}
			}
			,
			{
				path: '/software-activation-info',
				name: 'SoftwareActivationInfo',
				component: () => import('@/views/SoftwareActivation/info'),
				meta: {
					requireAuth: true
				}
			}
		]
	}
];

const router = new VueRouter({
	routes
});

// 页面打开之前
router.beforeEach((to, from, next) => {
	if (to.meta.requireAuth) {
		if (store.state.gToken) {
			next()
		} else {
			next('/login')
		}
	} else {
		if (to.path === '/login' && store.state.gToken) {
			next(from.path)
		} else {
			next()
		}
	}
	next()
});

//router path重复异常处理
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};

export default router;