import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './assets/css/element-variables.scss'
import { mixin } from '@/mixins'
import './utils/message'
import enLocale from 'element-ui/lib/locale/lang/en'; // 英文语言包
 
Vue.use(ElementUI, {locale: enLocale});

Vue.mixin(mixin)
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
