import axios from "../request"

//server version info
export const getAppver = () => axios.get('/appver')

//login
export const login = (data) => axios.post('/en-client/member/login', data)
//change password
export const updatePwdHttp = (data) => axios.post('/en-client/member/change-pwd', data)

//获取用户购买软件列表
export const listMemberSoftappHttp = (memberId) => axios.get('/en-client/member-softapp',{
    params:{'memberId':memberId}
})
//通过 产品序列码获取 产品
export const getProductByNoHttp = (productNo) => axios.get('/en-client/product/product-no',{
    params:{'productNo':productNo}
})

//获取注册列表
export const listProductRegisterHttp = (memberId) => axios.get(`/en-client/product-register/${memberId}`)

//注册激活
export const addProductRegisterHttp = (data) => axios.post('/en-client/product-register', data)

//获取电脑登录记录
export const listMemberMacRecordHttp = (memberId,softappId) => axios.get('/en-client/member-machine-record',{
    params:{
        'memberId':memberId,
        'softappId':softappId
    }
})
//获取软件功能列表
export const listMemberSoftFeatureHttp = (memberId,softappId) => axios.get('/en-client/member-softapp-feature',{
    params:{
        'memberId':memberId,
        'softappId':softappId
    }
})
//使用激活码
export const useAuthorizationCodeHttp = (code,data) => axios.post(`/en-client/member-softapp-feature/${code}/active`,data)
//获取激活码订单列表
export const listAuthorizationOrderHttp = (memberId,softappId) => axios.get('/en-client/authorization-order',{
    params:{
        'memberId':memberId,
        'softappId':softappId
    }
})
//根据激活码获取激活码订单信息
export const getOrderByCodeHttp = (code) => axios.get(`en-client/authorization-order/${code}/code`)
//删除电脑登录记录
export const delMemberMacRecordHttp = (loginRecordId) => axios.post(`en-client/member-machine-record/${loginRecordId}/del`)


// register
// 获取注册验证码图片
export const getCaptchaImageHttp = () => axios.get(`/en-client/public/captcha-image`)
//发送注册验证码
export const sendEmailRegisterHttp = (data) => axios.post(`/en-client/public/send-email/register`,data)
export const checkCodeRegisterHttp = (data) => axios.post(`/en-client/public/check-code/register`,data)
//注册
export const registerHttp = (data) => axios.post(`/en-client/public/register`,data)
//发送找回密码验证码
export const sendEmailForgotHttp = (data) => axios.post(`/en-client/public/send-email/forgot`,data)
export const checkCodeForgotHttp = (data) => axios.post(`/en-client/public/check-code/forgot`,data)
//找回密码
export const forgotHttp = (data) => axios.post(`/en-client/public/forgot`,data)

