import axios from 'axios'
import { Message, Loading } from 'element-ui'
import store from '@/store'
import router from "@/router"

const service = axios.create({
	baseURL: `${process.env.VUE_APP_NP_URL}`,
	timeout: 12000
})
let loading = null
//http request拦截
service.interceptors.request.use(req => {
	loading = Loading.service({
		background: 'rgba(0, 0, 0, 0.7)'
	})
	if (store.state.gToken) {
		req.headers['x-token'] = store.state.gToken
	}
	return req
})
//http response拦截
service.interceptors.response.use(res => {
	loading.close()
	if (res.config.url == '/en-client/public/captcha-image') {
		store.commit('SET_VERIFYCODE', res.headers.verifycode)
	} else	if (res.status === 200) {
		if(res.headers['content-type'].includes('application/x-excel;charset=utf-8')){
			return res
		}
		const message = res.data.msg || '未知错误'
		if (res.data.code === 1 && (res.data.msg === '无效用户' || res.data.msg === 'Please login.')) {
			store.commit('Logout');
			router.replace("/login");
		} else if(res.data.code !== 0){
			Message({
				message: message,
				type: 'error',
				showClose: true
			});
			return Promise.reject(res.data)
		}
	}
	return res.data
})

export default service