<template>
	<div class="login">

		<el-form :model="loginForm" :rules="rules" status-icon ref="loginForm">
			<img src="@/assets/img/np_login_logo.svg" alt="" class="logo">
			<el-form-item prop="email" class="first">
				<el-input type="text" label="Email" placeholder="Email" v-model="loginForm.email"
									@keyup.native.enter="submitForm('loginForm')"></el-input>
			</el-form-item>
			<el-form-item prop="password" class="last">
				<el-input type="password" label="Password" placeholder="Password" v-model="loginForm.password"
									@keyup.native.enter="submitForm('loginForm')"></el-input>
			</el-form-item>
			<el-button @click="submitForm('loginForm')">Sign In</el-button>		
			<div>
			<el-row>
				<el-col :span="21">
					<div class="login-footer">New here? <router-link to="/register">Create an Account</router-link></div>
				</el-col>
				<el-col :span="3">
					<div class="login-footer"><router-link to="/forgot">Forgot</router-link></div> 
				</el-col>
			</el-row>
		</div>	
		</el-form>
		
	</div>
</template>

<script>
import { login } from '@/api/getData'

export default {
	name: 'Login',
	data() {
		return {
			loginForm: {
				email: '',
				password: ''
			},
			rules: {
				email: [
					{ required: true, trigger: 'blur', message: 'This email is required' }
				],
				password: [
					{ required: true, trigger: 'blur', message: "This password is required" }
				],
			}
		}
	},
	methods: {
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					login(this.loginForm).then(({ data }) => {
						this.$store.commit('SET_GTOKEN', data.token)
						this.$store.commit('SET_MEMBER_ID', data.id)
						this.$store.commit('SET_EMAIL', this.loginForm.email)
						this.$router.replace('/')
						this.$message.success('Login successful')
					})
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.login {
	header {
		height: 64px;
		line-height: 64px !important;
		font-size: 0.93vw;
		background-color: #b90025;

		span {
			color: #ffffff;
		}

		img {
			width: 108px;
			height: 19px;
			margin: 0 20px 0 32px;
		}
	}

	.el-form::v-deep {
		width: 370px;
		height: 400px;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		margin-bottom: 20px;

		.logo {
			width: 227px;
			height: 43px;
			margin-bottom: 21px;
		}

		.title {
			font-size: 1.04vw;
			color: #b90025;
			letter-spacing: 2.4px;
			margin-bottom: 100px;
		}

		.el-form-item {
			margin-bottom: 20px;
		}

		.el-input__inner {
			height: 48px;
			padding: 0 20px;
			font-size: 0.93vw;
		}

		.el-button {
			width: 370px;
			height: 50px;
			background: #b90025;
			border-radius: 6px;
			margin-top: 30px;
			font-size: 1.04vw;
			font-weight: 400;

			span {
				color: #ffffff;
			}
		}
	}
	.login-footer{
		text-align: left;
		margin-top: 15px;
		font-size: 0.72vw;
		a{
			color: #b90025;
			text-decoration:underline;
		}
	}
}
</style>