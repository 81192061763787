<template>
  <div>
    <h1>Noitom-Products Server build info </h1>
    <div class="appver">
        <p>run.host: {{appver['run.host']}}</p>
        <p>run.remote: {{appver['run.remote']}}</p>
        <p>server build.datetime: {{appver['build.datetime']}}</p>
        <p>server build.revision: {{appver['build.revision']}}</p>
    </div>
    <h1>Client build info </h1>
    <div>
      <p>client buildversion: {{h5ver['buildversion']}}</p>
      <p>client buildtime: {{h5ver['buildtime']}}</p>
    </div>
  </div>
</template>

<script>
import { getAppver } from "@/api/getData";
import h5BuildInfo from '../../buildinfo.json'

export default {
   //声明数据源
  data() {
    return {
      appver: {},
      h5ver:{}
    };
  },
  //定义方法
  methods: {

    getH5Version() {
			this.h5ver = h5BuildInfo
		},

    async getAppverData() {
      try {
        const result = await getAppver();
        console.log('getAppver()=>',result);

        if (result.code == 0) {
          this.appver = result.data
          console.log('appver', this.appver)
        }
      }catch(error){
          console.log(error)
      }
    },
  },
  mounted(){
      //页面渲染完成调用方法获取数据
      console.log("mounted");
      this.getH5Version();
      this.getAppverData();
  }
};
</script>

<style lang="scss" scoped></style>