import Vue from 'vue'
import Vuex from 'vuex'
import {
  setStore,
  getStore,
  clearStore
} from '@/utils/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    gToken: getStore({
      name: 'gToken'
    }) || '',//内部软件管理token
	  gEmail: getStore({
		  name: 'gEmail'
	  }) || '',//产品管理系统登录账号名
    gMemberId: getStore({
		  name: 'gMemberId'
	  }) || '',//产品管理系统登录账号ID
    gVerifycode: getStore({
      name: 'gVerifycode'
    }) || '',//验证码的key
  },
  mutations: {
    SET_GTOKEN: (state, gToken) => {
      state.gToken = gToken;
      setStore({
        name: 'gToken',
        content: state.gToken
      })
    },
	  SET_MEMBER_ID: (state, gMemberId) => {
      state.gMemberId = gMemberId;
      setStore({
        name: 'gMemberId',
        content: state.gMemberId
      })
    },
	  SET_EMAIL: (state, gEmail) => {
      state.gEmail = gEmail;
      setStore({
        name: 'gEmail',
        content: state.gEmail
      })
    },
    SET_VERIFYCODE: (state, gVerifycode) => {
      state.gVerifycode = gVerifycode;
      setStore({
        name: 'gVerifycode',
        content: state.gVerifycode
      })
    },
    Logout: (state) => {
	    state.gMemberId = state.gEmail = state.gVerifycode = state.gToken = ''
      clearStore();
    }
  },
  actions: {

  },
  getters: {
    gToken: state => state.gToken,
	  gMemberId: state => state.gMemberId,
	  gVerifycode: state => state.gVerifycode,
	  gEmail: state => state.gEmail,
  },
  modules: {
  }
})
