<template>
  <div class="home">
    <el-header>
      <div class="logo"><img src="@/assets/img/np_head_logo.svg" alt=""></div>&nbsp;&nbsp;

      <div class="set-wrap">   
        <span>{{gEmail}}</span>
        <span class="split-line"></span>
        <img src="@/assets/img/logout.svg" alt="" @click="logout">
      </div>
    </el-header>
    <el-container>
      <el-aside>
        <div class="active-title">{{activeName}}</div>
        <el-menu :default-active="activeMenu()"  text-color="#09202080" @select="handleSelect" class="my-custom-menu" ref="menu" router>
            <el-menu-item index="/my-account">My Account</el-menu-item>         
            <el-menu-item index="/product-registration">Product Registration</el-menu-item>            
            <el-menu-item index="/software-activation">Software Activation</el-menu-item>            
        </el-menu>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>

    </el-container>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      active: '',
      activeName:""
    }
  },
  updated(){
    this.setActive()
  },
  mounted() {
    this.setActive()
  },
  methods: {
    //退出
    logout() {
      this.$store.commit("Logout");
      this.$router.replace("/login");
    },
    setActive() {
      this.active = this.$route.path
      this.formatString(this.active)
    },
    //菜单选中
    activeMenu() {
      if (this.$route.path == '/software-activation-info') {
        return '/software-activation'
      }
      return this.$route.path
    },
    handleSelect(menuPath,indexPath){
      this.formatString(menuPath)
    },
    formatString(menuPath){
      // 去掉连字符  
      let withoutHyphens = menuPath.replace('/', '');  
      withoutHyphens = withoutHyphens.replace(/-/g, ' '); 
      // 分割字符串为单词数组  
      const words = withoutHyphens.split(' ');  
      // 将每个单词的首字母大写，并重新组合成字符串  
      const capitalizedWords = words.map(word => {  
        return word.charAt(0).toUpperCase() + word.slice(1);  
      });  
      // 返回格式化后的字符串  
      this.activeName =  capitalizedWords.join(' ');
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  .el-container{
    position: relative;
    min-height: 780px;
    width: 77%;
    margin: 0px 11.5%;
    .active-title{
      font-size: 0.8vw;
      margin: 2.6vw 0px;
      color: rgba(9, 32, 32, 0.5);
    }
  }
  header {
		height: 64px;
		line-height: 64px !important;
		font-size: 0.93vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
		background-color: #b90025;
		span {
			color: #ffffff;
		}
    .logo {
      height: 20px;
      display: flex;
      align-items: center;
      font-size: 0.83vw;
      font-weight: 400;
      color: #828690;

      >img {
        width: 108px;
			  height: 19px;
			  margin: 0 20px 0 32px;
      }
    }
    .set-wrap {
      display: flex;
      align-items: center;

      .split-line {
        width: 4px;
        height: 35px;
        border-radius: 10px;
        margin: 0 32px;
        background: rgba(216, 216, 216, .4);
      }
      >img {
        background-color: #ffffff;
        cursor: pointer;
      }
    }
	}
 
}
.el-main{
  margin-top: 6.25vw;
}
.el-aside{
    width: 10.6vw !important;
    min-height: calc(100vh - 48px);
    padding: 1.15vw 8px 0;
    
    .el-menu {
      border: none;
      background-color: #F9F9F9!important;
      height: 70%!important;

      .el-menu-item, .el-submenu__title {
        height: 60px;
        line-height: 60px;
        font-size: 0.83vw;
        margin-bottom: 8px;

        > img {
          width: 20px;
          margin-right: 16px;
        }
      }

      .el-menu-item.is-active {
        color: #b90025!important;
      }

      .el-submenu__title, .el-menu-item {
        padding-left: 1.2vw !important;
      }

      .el-submenu .el-menu-item {
        padding-left: 60px !important;
      }
    }
  }
</style>